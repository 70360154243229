<template>
    <div>
        <b-alert
            :show="alertShow"
            variant="danger"
        >
            <b-icon icon="info-circle" class="mr-2"></b-icon>
            <span v-html="message"></span>
        </b-alert>
    </div>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            message: null,
            alertShow: false,
        };
    },
    watch: {
        user: {
            immediate: true,
            handler(newValue) {
                this.messages(newValue);
            }
        }
    },
    methods: {
        messages(user) {
            this.alertShow = true;

            if (
                user?.student?.student_program?.[0]?.nationality_code === 'TR'
            ) {
                this.message = user.student_information_messages.tr;
            } else {
                this.message = user.student_information_messages.en;
            }
        }
    }
};
</script>
<style>
    .alert.alert-danger {
        background-color: #f8d7da;
    }
</style>
